<template>
    <span v-show="show_approve_reject_buttons_ref.valueOf()">
        <!--components/PendingApproveRejectComponent.vue-->
        <button class="btn btn-sm btn-primary btn-with-margin" @click="handle_approve_link">link</button>
        <button class="btn btn-sm btn-outline-primary btn-with-margin" @click="handle_approve_copy">copy</button>
        <button class="btn btn-sm btn-outline-danger btn-with-margin" @click="handle_reject">reject</button>
    </span>
</template>

<script setup>
const props = defineProps({
  task: {
    type: Object,
    required: true,
  }
});

const route = useRoute();
let show_approve_reject_buttons_ref = ref(false);
let space_dropdown_state = useSpaceDropdownState();
let update_notification_bell_state = useUpdateNotificationBellState();
let current_wsid = space_dropdown_state.value;
if ((current_wsid) && (props.task.workspace_status)) {
  if ((props.task.workspace_status[current_wsid]?.state.toLowerCase() == 'pending') && (hasPrivilege('workspace.approve_publish', current_wsid))) {
    show_approve_reject_buttons_ref.value = true;
  }
}

const handle_approve_copy = async () => {
  let wsid = space_dropdown_state.value;
  let task_id = props.task.id;
  let result = await approveTaskPublish(task_id, wsid, "copy", true);
  show_approve_reject_buttons_ref.value = false;
  update_notification_bell_state.value = update_notification_bell_state.value + 1;

  if (route.path.startsWith("/tasks/")) {
    let new_task_id = result?.status?.copied_as;
    if (new_task_id) {
      dkAlert("Copied.  You will now be taken to the new task.", async function(){
        await navigateToWrapper("/tasks/" + new_task_id)
      })
    }
  }
}

const handle_approve_link = async () => {
  let wsid = space_dropdown_state.value;
  let task_id = props.task.id;
  await approveTaskPublish(task_id, wsid, "link", true);
  show_approve_reject_buttons_ref.value = false;
  update_notification_bell_state.value = update_notification_bell_state.value + 1;
}

const handle_reject = async () => {
  let wsid = space_dropdown_state.value;
  let task_id = props.task.id;
  await approveTaskPublish(task_id, wsid, "reject", true);
  show_approve_reject_buttons_ref.value = false;
  update_notification_bell_state.value = update_notification_bell_state.value + 1;
}

</script>

<style scoped>
  .btn-with-margin {
    margin: 0px 10px;
  }
</style>